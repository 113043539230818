<template>
  <el-aside width="">
    <el-menu
      default-active="1-4-1"
      class="el-menu-vertical-demo"
      :collapse="!drawer"
    >

      <el-submenu
        v-if="hasLogin && !isOpsUser"
        index="1"
        :style="{ width: drawer ? '199px' : '60px' }"
      >
        <template slot="title">
          <i class="el-icon-tickets"></i>
          <span slot="title">需求管理</span>
        </template>

        <el-menu-item-group>
          <span slot="title">Requirements</span>
          <el-menu-item index="1-1" @click="routeTo('Requirements')" >需求</el-menu-item>
          <el-menu-item index="1-2" @click="routeTo('Overview')" >总览</el-menu-item>
          <el-menu-item index="1-3" @click="routeTo('Tracking')" >进度</el-menu-item>
          <el-menu-item index="1-4" @click="routeTo('Sync')" :disabled="!mustAboveNormal" >拉表</el-menu-item>
          <el-menu-item index="1-5" @click="routeTo('Tools')">工具</el-menu-item>
        </el-menu-item-group>

        <el-menu-item-group>
          <span slot="title">MasterLists</span>
          <el-menu-item index="1-6" @click="routeTo('Masterlist')" >主数据</el-menu-item>
<!--          <el-menu-item index="1-7" @click="routeTo('Search')">查询</el-menu-item>-->
        </el-menu-item-group>

      </el-submenu>

      <el-submenu
        v-if="hasLogin && !isOpsUser"
        index="2"
        :style="{ width: drawer ? '199px' : '60px' }"
      >
        <template slot="title">
          <i class="el-icon-data-analysis"></i>
          <span slot="title"
                @click="routeTo('DeliveryStatus')"
                >项目状态</span>
        </template>
        <el-menu-item-group>
          <span slot="title">Delivery</span>
          <el-menu-item index="2-1" @click="routeTo('DeliveryStatus')" >交付状态</el-menu-item>
          <el-menu-item index="2-2" @click="routeTo('AutoCommit')" >自助提交</el-menu-item>
          <el-menu-item index="2-3" @click="routeTo('Repair')">异常站点</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group title="Duty">
          <el-menu-item index="2-4" @click="routeTo('DutyReport')">日报</el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-submenu
        v-if="hasLogin && !isOpsUser"
        index="3"
        :style="{ width: drawer ? '199px' : '60px' }"
      >
        <template slot="title">
          <i class="el-icon-menu"></i>
          <span slot="title"
                @click="routeTo('Handle')"
          >调度管理</span>
        </template>
          <el-menu-item
            index="3-1"
            @click="routeTo('Handle')"
          >任务处理</el-menu-item>
          <el-menu-item
            index="3-2"
            @click="routeTo('Test')"
          >爬虫提测</el-menu-item>
          <el-menu-item
            index="3-3"
            @click="routeTo('Schedule')"
          >调度处理</el-menu-item>
<!--          <el-menu-item-->
<!--            index="3-3"-->
<!--            @click="routeTo('Mute')"-->
<!--          >告警处理</el-menu-item>-->
      </el-submenu>

      <el-menu-item v-if="hasLogin && !isOpsUser" index="4" @click="routeTo('SpiderMgmt')"
                    :style="{ width: drawer ? '199px' : '60px' }">
        <i class="el-icon-cpu"></i>
        <span slot="title">爬虫管理</span>
      </el-menu-item>

      <el-menu-item v-if="hasLogin" index="5" @click="routeTo('ResponseGroup')"
                    :style="{ width: drawer ? '199px' : '60px' }">
        <i class="el-icon-zoom-in"></i>
        <span slot="title">响应回溯</span>
      </el-menu-item>

      <!--      2024-06-07 感觉wiki里的内容都相对过时了，没人维护，而且容易分裂飞书wiki。先下线吧。-->
      <!--      <el-menu-item v-if="hasLogin && !isTestTeam" index="6" @click="routeTo('ShowWiki')"-->
      <!--                    :style="{ width: drawer ? '199px' : '60px' }">-->
      <!--        <i class="el-icon-reading"></i>-->
      <!--        <span slot="title">Wiki</span>-->
      <!--      </el-menu-item>-->

      <!--      <el-submenu-->
      <!--        index="6"-->
      <!--        :style="{ width: drawer ? '199px' : '60px' }"-->
      <!--      >-->
      <!--        <template slot="title">-->
      <!--          <i class="el-icon-reading"></i>-->
      <!--          <span slot="title"-->
      <!--                @click="routeTo('Wiki')"-->
      <!--          >Wiki</span>-->
      <!--        </template>-->
      <!--          <el-menu-item index="6-1" @click="routeTo('ShowWiki')" >主数据更新</el-menu-item>-->
      <!--          <el-menu-item index="6-2">取数逻辑</el-menu-item>-->
      <!--          <el-menu-item index="6-3">调度逻辑</el-menu-item>-->
      <!--          <el-menu-item index="6-4">交付逻辑</el-menu-item>-->
      <!--      </el-submenu>-->

    </el-menu>
  </el-aside>
</template>

<script>
export default {
  name: 'AppSidebar',
  data() {
    return {
      drawer: true,
    };
  },
  computed: {
    currentUserRole() {
      return this.$auth.user().role;
    },
    currentRoute() {
      console.log(this.$route.name);
      return this.$route.name;
    },
    mustAboveNormal() {
      return this.currentUserRole !== '';
    },
    user() {
      return this.$auth.token() && this.$store.state.auth ? this.$store.state.auth.user || {} : {};
    },
    hasLogin() {
      return Object.keys(this.user).length !== 0;
    },
    isOpsUser() {
      return this.currentUserRole.toLowerCase() === 'ops' || this.user.username === 'testteam';
    },
  },
  methods: {
    toggleSidebar() {
      this.drawer = !this.drawer;
    },
    routeTo(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}
</style>
